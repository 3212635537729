// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bQ_kz";
export var caseStudyBackground__lineColor = "bQ_kq";
export var caseStudyHead__imageWrapper = "bQ_kn";
export var caseStudyProjectsSection = "bQ_kB";
export var caseStudyQuote__bgLight = "bQ_kx";
export var caseStudyQuote__bgRing = "bQ_kw";
export var caseStudyQuote__bottomVideo = "bQ_kL";
export var caseStudyVideo = "bQ_kD";
export var caseStudyVideo__ring = "bQ_kF";
export var caseStudy__bgDark = "bQ_km";
export var caseStudy__bgLight = "bQ_kl";